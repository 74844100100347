import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';

@Component({
  selector: 'app-delivery-times',
  templateUrl: './delivery-times.page.html',
  styleUrls: ['./delivery-times.page.scss'],
})
export class DeliveryTimesPage implements OnInit {

  public prices: any[] = [];
  public currentSlotId: any = null;

  constructor(public popover: PopoverController, public appService: AppServiceService) {

  }

  ngOnInit() {
    let postalCodeDetails = this.appService.appObject.postalCodeDetails;
    let selectedRestaurantPricing = postalCodeDetails.price.find((prices) => prices.restaurantId === this.appService.appObject.selectedRestaurantId);
    if (selectedRestaurantPricing && selectedRestaurantPricing.time_slots) {
      this.prices = selectedRestaurantPricing.time_slots.filter((slot) => slot.status === 'true');
    }else{
      this.prices = [];
    }

    let currentTimeSlot = this.appService.getGivenPostalCodeCurrentPriceAndMinAmount(selectedRestaurantPricing)
    // console.log('currentTimeSlot :', currentTimeSlot);
    if (currentTimeSlot && currentTimeSlot.id) this.currentSlotId = currentTimeSlot.id; else this.currentSlotId = null;
  }

  ngDoCheck() {
    // display touchable area to change favourite restaurant
    // let ele = document.getElementById('enable-tour-container');
    // if (ele) {
    //   let bounding = ele.getBoundingClientRect();
    //   if (bounding) {
    //     let holeEle = document.getElementById('secret-div-web-menu');
    //     if (holeEle) holeEle.setAttribute('style', 'top:' + ((bounding.top) + 'px;left : ' + (bounding.left + 'px;width:') + ((bounding.width + 1) + 'px; height: ') + ((bounding.height + 1) + 'px')));
    //   }
    // }
  }

  dismiss() {
    this.popover.dismiss();
  }

}
